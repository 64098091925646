<template>
  <ContentBlock
    overflow-hidden
    no-padding-bottom
  >
    <AddUserModal
      :id="modalIds.ADD_USER"
      :roles="roles"
      :catalogs="catalogs"
      :taken-user-ids="takenUserIds"
      @create="handleCreateNewUser"
    />
    <EditUserModal :id="modalIds.EDIT_USER" />

    <div class="subpage__title heading-3-sb grey-100">
      <template>
        {{ $t('WiMultiuserTab.Ui.usersBtn') }}
      </template>

      <div class="subpage__title-actions">
        <SlButton
          :disabled="!$sl_hasAccess($sl_features.manageUsers)"
          @click="handleAddNewUser"
        >
          <template #prepend>
            <icon
              data="@icons/plus.svg"
              class="size-20 color-white-stroke"
            />
          </template>
          {{ $t('WiMultiuserTab.NewUser') }}
        </SlButton>
      </div>
    </div>
    <div
      v-if="old_users.totalRowsCount"
      class="subpage__tabs"
    >
      <SlTabList
        v-model="listTab"
        :tabs="listTabsConfig"
        horizontal
      />
    </div>
    <div
      :class="['subpage__table-wrapper', {
        'subpage__table-wrapper--tabs': old_users.totalRowsCount
      }]"
    >
      <SlTabContent
        :value="listTabsConfig[0].value"
        :tab-value="listTab"
        full-height
      >
        <SlTable
          id="users-tab"
          :headers="tableHeaders"
          :rows="tableRows"
          unique-key="id"
          header-unique-key="key"
          header-name-key="name"
          header-type-key="type"
          :row-height="40"
          :initial-col-sizes="['24%', '24%', '24%', '24%', '40px']"
          :hidden-columns-keys="['id', 'ActionFgs']"
          :max-height="1000"
          :col-initial-width="40"
          :col-resize="false"
          :value-parser="parseValue"
          list
        >
          <template #header-ActionFgs-last />

          <template #ActionFgs-last="slotScope">
            <UserActionsDropdown
              :user="slotScope"
              :actions="slotScope.cellValue.val"
            />
          </template>
        </SlTable>
      </SlTabContent>
      <SlTabContent
        v-if="listTabsConfig[1]"
        :value="listTabsConfig[1].value"
        :tab-value="listTab"
        full-height
      >
        <SlButton
          class="mb-16"
          @click="handleMigrateUsers"
        >
          <template #prepend>
            <icon
              data="@icons/send.svg"
              class="size-16 fill-off"
            />
          </template>
          {{ $t('Web.Users.SendInvite') }}
        </SlButton>
        <SlTable
          id="users-tab"
          :headers="oldTableHeaders"
          :rows="oldTableRows"
          unique-key="id"
          header-unique-key="key"
          header-name-key="name"
          header-type-key="type"
          :row-height="40"
          :initial-col-sizes="['24%', '24%', '24%', '24%', '4%']"
          :hidden-columns-keys="['id', 'ActionFgs']"
          :max-height="1000"
          :col-resize="false"
          :value-parser="parseValue"
          list
        >
          <template #header-ActionFgs-last />

          <template #ActionFgs-last="slotScope">
            <UserActionsDropdown
              :user="slotScope"
              :actions="slotScope.cellValue.val"
              is-migration
            />
          </template>
        </SlTable>
      </SlTabContent>
    </div>
  </ContentBlock>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import AddUserModal from '@/components/Modals/User/AddUserModal.vue';
import EditUserModal from '@/components/Modals/User/EditUserModal.vue';
import modalIds from '@/config/shared/modalsId.config';
import { toArray } from '@/helpers/utils/toArray';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import UserActionsDropdown from '@/components/User/ActionDropdowns/UserActionsDropdown.vue';

const DEFAULT_TAB = 'active';

export default {
  name: 'UsersTab',
  components: {
    UserActionsDropdown,
    AddUserModal,
    EditUserModal
  },
  mixins: [modal, access],
  props: {
    saveConfirm: Boolean
  },
  data() {
    return {
      modalIds,
      confirmDelete: false,
      listTabsConfig: [
        {
          label: this.$t('Web.Users.ActiveUsers'),
          value: DEFAULT_TAB
        },
        {
          label: this.$t('Web.Users.WaitingForMigration'),
          value: 'migration'
        }
      ],
      listTab: DEFAULT_TAB
    };
  },
  computed: {
    ...mapState({
      users: state => state.userAccess.users || {},
      old_users: state => state.userAccess.old_users || {}
    }),
    ...mapGetters({
      roles: 'userAccess/rolesItems',
      catalogs: 'userAccess/permissionsItems',
      takenNamesByKey: 'userAccess/takenNamesByKey',
      currentUserId: 'user/userId'
    }),
    tableHeaders() {
      return this.users.headers || [];
    },
    tableRows() {
      return this.users.rows || [];
    },
    oldTableHeaders() {
      return this.old_users.headers || [];
    },
    oldTableRows() {
      return this.old_users.rows || [];
    },
    takenUserIds() {
      return this.takenNamesByKey('users', (user) => user.id);
    }
  },
  watch: {
    oldTableRows(val) {
      if (val?.length === 0) {
        this.listTab = DEFAULT_TAB;
      }
    }
  },
  methods: {
    ...mapActions('userAccess', [
      'createUser',
      'deleteUser',
      'updateUser',
      'migrateUsers',
      'updateOldUser'
    ]),
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || '';
    },
    async handleCreateNewUser(payload) {
      try {
        await this.createUser(payload);
      } catch (e) {
        if (e.code === slErrorCodes.RESOURSE_ALREADY_EXISTS) {
          return this.$notify({
            type: 'error',
            title: this.$t('Web.Error.UserExists')
          });
        }

        if (e.code === slErrorCodes.LIMIT_REACHED) {
          return this.$notify({
            type: 'error',
            title: this.$t('Common.Warning'),
            text: this.$t('WiMultiuserTab.Other.TeamIsFull'),
            duration: -1
          });
        }
      }
    },
    handleAddNewUser() {
      this.showModal(modalIds.ADD_USER);
    },
    async handleMigrateUsers() {
      try {
        await this.migrateUsers();
      } catch (err) {
        const error = err?.data;
        const errorCode = error?.error?.code;

        if (errorCode === slErrorCodes.REPEAT_EMAIL) {
          return this.$notify({
            type: 'error',
            title: error?.error?.description,
            duration: -1
          });
        }

        const defaultErrorTitle = this.$t('Web.Error.UserMigrationTitle');
        const defaultErrorText = this.$t('Web.Error.UserMigrationText');

        if ([
          slErrorCodes.MIGRATION_FAILED,
          slErrorCodes.MIGRATION_PART_FAILED
        ].includes(errorCode)
        ) {
          const usersFailedToMigrate = toArray(error?.payload?.failedToMigrate?.username).map(user => user.val);
          const errorText = this.$t('Web.Users.MigrationFail', { 1: usersFailedToMigrate.join(', ') });

          return this.$notify({
            type: 'error',
            title: error?.error?.description,
            text: `${errorText} ${defaultErrorText}`,
            duration: -1
          });
        }

        return this.$notify({
          type: 'error',
          title: defaultErrorTitle,
          text: defaultErrorText,
          duration: -1
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/users/user-list.scss";

.subpage__table-wrapper {
  height: calc(100% - 82px);

  &--tabs {
    height: calc(100% - 130px);
  }
}

.mb-16 {
  margin-bottom: 16px;
}
</style>
